import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'
import utils from '../utils'

export default function SignalLayer(): ReactElement {
  const mode = 'full_rgi_line_geo'
  return (
    <Source
      type="vector"
      url={utils.formatLayerUrl('cassini_v2_gaia_signal', mode)}
    >
      <Layer
        id="signal-layer-carre"
        type="symbol"
        source-layer="cassini_v2_gaia_signal"
        paint={{}}
        layout={{
          'icon-image': 'pulsing-signal',
          'icon-size': 0.5,
          visibility: 'visible',
          'icon-allow-overlap': true,
        }}
        filter={['in', ['get', 'type_installation_fixe_id_mnemo'], ['literal', ['CARRE']]]}
        minzoom={7}
      />
      <Layer
        id="signal-layer-cv"
        type="symbol"
        source-layer="cassini_v2_gaia_signal"
        paint={{}}
        layout={{
          'icon-image': 'CV',
          'icon-size': 0.5,
          visibility: 'visible',
          'icon-allow-overlap': true,
        }}
        filter={['in', ['get', 'type_installation_fixe_id_mnemo'], ['literal', ['CV']]]}
        minzoom={7}
      />
    </Source>
  )
}
