import Map from 'components/Map/Map'
import { ReactElement, MutableRefObject, useRef } from 'react'
import { MapRef } from 'react-map-gl'

export default function Home(): ReactElement {
  const mapRef: MutableRefObject<MapRef | undefined> | undefined = useRef()
  return (
    <Map mapRef={mapRef} />
  )
}
