import { useState, useEffect } from 'react'
import jsonData from '2025.json'
import './GeojsonList.css'

interface FeatureProperties {
  id: string
  famille: string
}

interface Feature {
  properties: FeatureProperties
  geometry: {
    type: 'Point' | 'MultiPoint' | 'LineString' | 'MultiLineString'
    coordinates: [number, number]
  };
}

interface FamilyGroup {
  famille: string
  features: Feature[]
}

const jsonDataTyped = jsonData as FeatureProperties[]

function GeojsonFamilyList() {
  const [familyGroups, setFamilyGroups] = useState<FamilyGroup[]>([])
  const [openFamily, setOpenFamily] = useState<string | null>(null)
  const [openFeatureId, setOpenFeatureId] = useState<string | null>(null)

  useEffect(() => {
    const fetchAndProcessData = async () => {
      try {
        // Chargement des données GeoJSON
        const response = await fetch('/2025.geojson')
        if (!response.ok) throw new Error('Erreur lors du chargement du fichier GeoJSON')
        const geoJsonData = await response.json()

        // Fusion des propriétés GeoJSON et jsonData pour chaque feature
        const mergedFeatures = geoJsonData.features.map((feature: Feature) => {
          const additionalProperties = jsonDataTyped.find(obj => obj.id === feature.properties.id)
          return { ...feature, properties: { ...feature.properties, ...additionalProperties } }
        })

        // Regroupement des features par famille
        const groupedByFamily: { [key: string]: Feature[] } = {}
        mergedFeatures.forEach((feature: Feature) => {
          const family = feature.properties.famille
          groupedByFamily[family] = groupedByFamily[family] || []
          groupedByFamily[family].push(feature)
        })

        // Conversion du regroupement en un tableau pour utilisation dans l'état
        const familyGroupsArray: FamilyGroup[] = Object.keys(groupedByFamily).map(famille => ({
          famille, features: groupedByFamily[famille],
        }))

        // Mise à jour de l'état avec les groupes de familles
        setFamilyGroups(familyGroupsArray)
      } catch (error) {
        console.error(error)
      }
    }

    fetchAndProcessData()
  }, [])

  // clic sur un nom de famille pour ouvrir sa liste de features
  const handleFamilyClick = (famille: string) => {
    setOpenFamily(openFamily === famille ? null : famille)
  }

  // clic sur un ID de feature pour afficher ses détails
  const handleFeatureClick = (id: string) => {
    setOpenFeatureId(openFeatureId === id ? null : id)
  }

  return (
    <div className="container">
      {familyGroups.map(group => (
        <div key={group.famille} className="family-group">
          <button
            type="button"
            className="accordion"
            onClick={() => handleFamilyClick(group.famille)}
          >
            {group.famille}
          </button>

          {openFamily === group.famille && (
            <ul>
              {group.features.map(feature => (
                <li
                  key={feature.properties.id}
                  className={openFeatureId === feature.properties.id ? 'open' : ''}
                >
                  <button
                    type="button"
                    className="feature-header"
                    onClick={() => handleFeatureClick(feature.properties.id)}
                  >
                    Feature ID:
                    {feature.properties.id}
                  </button>

                  {openFeatureId === feature.properties.id && (
                    <div className="feature-details">
                      {Object.entries(feature.properties).map(([key, value]) => (
                        <div key={key}>
                          <span className="text-label">
                            {key}
                            :
                          </span>
                          <span className="text-value">
                            {value !== undefined && value !== null ? value.toString() : 'Null'}
                          </span>
                        </div>
                      ))}
                      <div>
                        <span className="text-label">
                          {feature.geometry.type}
                          :
                        </span>
                        <br />
                        <span className="text-value">
                          {feature.geometry.coordinates.join(', ')}
                        </span>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  )
}

export default GeojsonFamilyList
