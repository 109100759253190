import { auth } from '@osrdata/app_core'
import 'App.scss'
import {
  ReactElement, Suspense, useEffect, useState,
} from 'react'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import AccessDenied from 'components/AccessDenied/AccessDenied'
import Loader from 'components/Loader/Loader'
import TabList from 'components/TabList/TabList'
import TopBar from 'components/TopBar/TopBar'
import { terms } from 'common/terms'
import { HOME_PATH } from 'utils/navRoutes'
import navTabElements from 'utils/navTabElements'
import { useAppDispatch, useAppSelector } from 'hooks'
import GeojsonFamilyList from 'components/GeojsonList/GeojsonList'
import Home from 'pages/home/Home'

const APP_NAME = 'poc-sia'
const ACCESS_PERM = 'poc-sia::ACCESS'

export default function App(): ReactElement {
  const dispatch = useAppDispatch()
  const { isLogged, appPermissions, isLoading } = useAppSelector(state => state.user)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      if (!appPermissions.includes(ACCESS_PERM)) {
        setAccessDenied(true)
      }
    }
  }, [isLogged, appPermissions])

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <TopBar
          appName={APP_NAME}
          innerComponent={<TabList tabs={navTabElements} />}
          hasAccess={!isLoading && !accessDenied}
        />
        {isLoading && <div id="app"><Loader message={terms.Common.loading} /></div>}
        {!isLoading && (accessDenied ? <AccessDenied /> : (
          <div id="app">
            <Routes>
              <Route path={HOME_PATH} element={<Home />} />
              <Route path="/accueil/geojson" element={<GeojsonFamilyList />} />
              <Route path="*" element={<Navigate to={HOME_PATH} />} />
            </Routes>
          </div>
        ))}
      </BrowserRouter>
    </Suspense>
  )
}
