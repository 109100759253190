import config from 'config/config'

const getCSSValue = (name: string): string => getComputedStyle(document.documentElement).getPropertyValue(name)

const LAYER_URL = `${config.proxy}/chartis/v2/layer`
const formatLayerUrl = (sourceLayer: string, type: string, params = ''): string => {
  const layerUrl = `${LAYER_URL}/${sourceLayer}/mvt/${type}/?${params}`

  return layerUrl
}

export default {
  getCSSValue, formatLayerUrl,
}
