import { ReactElement } from 'react'
import Home from 'pages/home/Home'

export const HOME_PATH = '/accueil/'

export type NavRoute = {
  title: string
  path: string
  component: () => ReactElement
}

const navRoutes: NavRoute[] = [
  {
    title: 'Accueil',
    path: HOME_PATH,
    component: Home,
  },
]

export default navRoutes
