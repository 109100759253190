import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ViewportProps } from 'react-map-gl'

const DEFAULT_VIEWPORT: ViewportProps = {
  latitude: 46.8025,
  longitude: 2.7643,
  zoom: 5.4389429465554,
  minZoom: 4.5,
  bearing: 0,
  pitch: 0,
}

export interface MapState {
  viewport: ViewportProps,
}

const initialState: MapState = {
  viewport: DEFAULT_VIEWPORT,
}

export const mapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {
    updateViewport: (state, action: PayloadAction<ViewportProps>) => {
      state.viewport = action.payload
    },
  },
})

export const {
  updateViewport,
} = mapSlice.actions

export default mapSlice.reducer
