/* eslint-disable camelcase */
import {
  MutableRefObject, Ref, useEffect, useState,
} from 'react'
import ReactMapGL, { MapRef, MapRequest, ViewportProps } from 'react-map-gl'
import { MAIN_API } from 'config/config'
import TrackLayer from 'components/TrackLayer/TrackLayer'
import mapStyle from 'assets/mapstyles/full.json'
import SignalLayer from 'components/SignalLayer/SignalLayer'
import signal_red from 'assets/logos/signal_red.png'
import signal_yellow from 'assets/logos/signal_yellow.png'
import signal_green from 'assets/logos/signal_green.png'

const isTileserverResource = (type: string) => (type === 'Source' || type === 'Tile')

const replaceUrl = (url: string): string => {
  if (!MAIN_API.proxy.includes('.dev.')) {
    const newEnv = MAIN_API.proxy.includes('staging') ? 'staging.dgexsol.' : 'dgexsol.'
    return url.replace('dev.dgexsol.', newEnv)
  }
  return url
}

const transformRequest = (url: string | undefined, resourceType: string | undefined): MapRequest => {
  if (url === undefined) return { url: '' }
  if ((isTileserverResource(resourceType as string))) {
    const newUrl = url.startsWith(MAIN_API.proxy) ? url : replaceUrl(url)
    return {
      url: newUrl,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }

  if (resourceType === 'Glyphs' || resourceType?.includes('Sprite')) {
    return { url: replaceUrl(url) }
  }
  return { url }
}

const size = 256
const getImageData = (img: ImageBitmap): Uint8ClampedArray | undefined => {
  const canvas = document.createElement('canvas')
  canvas.width = size
  canvas.height = size
  const context = canvas.getContext('2d')

  if (context) {
    context.drawImage(img, 0, 0, size, size)
    return context.getImageData(0, 0, size, size).data
  }
  return undefined
}

const pulsingSignal = {
  width: size,
  height: size,
  data: new Uint8ClampedArray(size * size * 4),
  redSignal: new Uint8ClampedArray(size * size * 4),
  yellowSignal: new Uint8ClampedArray(size * size * 4),
  greenSignal: new Uint8ClampedArray(size * size * 4),
  map: { triggerRepaint: () => undefined },
  value: 'red',

  onAdd(map: any) {
    this.data = this.redSignal
    this.map = map
  },

  render() {
    this.map.triggerRepaint()

    const duration = 5000
    const t = (performance.now() % duration) / duration

    let newValue = this.value

    if (t < (1 / 3)) {
      newValue = 'red'
    } else if (t < (2 / 3)) {
      newValue = 'yellow'
    } else {
      newValue = 'green'
    }

    if (newValue === this.value) return false

    if (t < (1 / 3)) {
      this.value = 'red'
      this.data = this.redSignal
    } else if (t < (2 / 3)) {
      this.value = 'yellow'
      this.data = this.yellowSignal
    } else {
      this.value = 'green'
      this.data = this.greenSignal
    }
    return true
  },
}

interface Props {
  mapRef: MutableRefObject<MapRef | undefined> | undefined
}

function Map({ mapRef }: Props) {
  const [viewport, setViewport] = useState<ViewportProps>({
    latitude: 46.8025,
    longitude: 2.7643,
    zoom: 5.4389429465554,
    bearing: 0,
    pitch: 0,
  })

  const handleViewportChange = (newViewport: ViewportProps) => {
    setViewport({ ...newViewport, transitionDuration: 0 })
  }

  useEffect(() => {
    if (mapRef?.current) {
      const currentMap = mapRef?.current?.getMap()
      currentMap.on('load', async () => {
        const animatedSignal = {
          ...pulsingSignal,
        }
        await currentMap.loadImage(signal_red, (error: Error, image: ImageBitmap) => {
          if (error) return
          animatedSignal.redSignal = getImageData(image) as Uint8ClampedArray
        })
        await currentMap.loadImage(signal_yellow, (error: Error, image: ImageBitmap) => {
          if (error) return
          animatedSignal.yellowSignal = getImageData(image) as Uint8ClampedArray
        })
        await currentMap.loadImage(signal_green, (error: Error, image: ImageBitmap) => {
          if (error) return
          animatedSignal.greenSignal = getImageData(image) as Uint8ClampedArray
        })

        currentMap.addImage('pulsing-signal', animatedSignal, { pixelRatio: 2 })
      })
    }
  }, [mapRef])

  return (
    <div style={{
      position: 'relative',
      width: '100%',
      height: '100%',
    }}
    >
      <ReactMapGL
        {...viewport}
        ref={mapRef as Ref<MapRef>}
        width="100%"
        height="100%"
        transformRequest={transformRequest}
        onViewportChange={handleViewportChange}
        mapStyle={mapStyle}
        interactiveLayerIds={[]}
      >
        <TrackLayer />
        <SignalLayer />
      </ReactMapGL>
    </div>
  )
}

export default Map
