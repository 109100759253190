import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'
import utils from '../utils'

export default function TrackLayer(): ReactElement {
  const mode = 'full_rgi_line_geo'
  const params = 'type_habillage_id_libelle=Statut'
  return (
    <Source
      type="vector"
      url={utils.formatLayerUrl('cassini_v2_gaia_habillage_valeur_margin_100', mode, params)}
    >
      <Layer
        id="track-layer"
        type="line"
        source-layer="cassini_v2_gaia_habillage_valeur_margin_100"
        paint={{
          'line-color': '#0000ff',
          'line-width': 2,
        }}
        layout={{
          visibility: 'visible',
        }}
        filter={[
          'all',
          ['any',
            ['==', ['get', 'valeur_libelle'], 'Exploitée'],
            ['==', ['get', 'valeur_libelle'], 'Transférée en voie de service'],
          ],
        ]}
      />
    </Source>
  )
}
